import { projectHelper } from '../../lib-sat/misc/project-helper';
import { assertIsNonEmptyString, assertIsObject, assertObjectHasKey } from '../test-and-assert/assert-base';
import { testIsNonEmptyString, testIsString } from '../test-and-assert/test-base';
import { browser, getBrowser } from "../../lib-sat/misc/browser-detector";

/**
 * you can use this global variable to set a browser extension id via console for testing
 *
 * @type {?String}
 */
global.qgBrowserExtensionId = null;

/**
 * @return {string|null}
 */
function getBrowserExtensionId() {
    // if browser extension id was set via console then we use that
    if (testIsNonEmptyString(global.qgBrowserExtensionId)) {
        return global.qgBrowserExtensionId;
    }

    if (projectHelper.isLocalDev()) {
        // add the id of you local extension here for local testing
        return 'pkiaghlmagmbgadcjgbfhahpcmbbpbem';
    }

    if (projectHelper.isImg2go()) {
        return 'kpecfgjcmboghimfnkpcfijmoknhipdi';
    }

    if (projectHelper.isOnlineConvert() && getBrowser() === browser.CHROME) {
        return 'dicgkflojhbopmagcacdklcpdfdcnhko';
    }

    if (projectHelper.isOnlineConvert() && getBrowser() === browser.EDGE) {
        return 'gieflphfabklgnpjlaedlcpkcmggjago';
    }

    if (projectHelper.isPdf2go() && getBrowser() === browser.CHROME) {
        return 'dfnhijmficoiilogkjlnkionfjlgecdi';
    }

    if (projectHelper.isPdf2go() && getBrowser() === browser.EDGE) {
        return 'chnkimkmeimobjodllnkpbjchdbinjla';
    }

    if (projectHelper.isVideo2edit()) {
        return 'djanbckaomofhalpklkcbimkmlaocddd';
    }

    return null;
}

export { getBrowserExtensionId };

/**
 * @param {String|Object} messageData
 *
 * @return Promise
 */
function sendMessageToExtension(messageData) {
    if (testIsString(messageData)) {
        assertIsNonEmptyString(messageData);
        messageData = {
            type: messageData,
        };
    }

    assertIsObject(messageData, 'function needs string or object as paramter');

    assertObjectHasKey(messageData, 'type');

    const id = getBrowserExtensionId();

    if (id === null) {
        if (projectHelper.isLocalDev()) {
            console.log('no browser extension id for current project');
        }

        return Promise.reject('no browser extension id for current project');
    }

    if (typeof chrome === 'undefined' || !chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
        return Promise.reject('no chrome runtime sendmessage');
    }

    return new Promise((resolve, reject) => {
        function callback(data) {
            // if data is empty and there is lastError, then the sendMessage failed
            if (!data && chrome.runtime.lastError) {
                if (projectHelper.isLocalDev()) {
                    console.log('error in callback from runtime.sendMessage');
                    console.log(chrome.runtime.lastError.message);
                }

                reject('error while running sendMessage: ' + chrome.runtime.lastError.message);

                return;
            }

            resolve(data);
        }

        chrome.runtime.sendMessage(id, messageData, {}, callback);
    });
}

export { sendMessageToExtension };

/**
 * @return {Promise}
 */
function getManifestVersion() {
    return sendMessageToExtension('version');
}

export { getManifestVersion };

/**
 * @return {Promise}
 */
function loginExtension() {
    return sendMessageToExtension('event-from-website-login');
}

export { loginExtension };

/**
 * Promise is resolved if installed
 * Promise is rejected if not installed
 *
 * @return {Promise}
 */
function isExtensionInstalled() {
    return sendMessageToExtension('event-from-website-is-installed');
}

export { isExtensionInstalled };
