class ProjectHelper {
    projectNames = {
        api2convert: 'API2Convert',
        audio2edit: 'Audio2Edit',
        compress2go: 'Compress2Go',
        ebook2edit: 'Ebook2Edit',
        metadata2go: 'Metadata2Go',
        img2go: 'Img2Go',
        ocr2edit: 'OCR2Edit',
        onlineConvert: 'Online-Convert',
        pdf2go: 'PDF2Go',
        video2edit: 'Video2Edit',
        satAdmin: 'SATAdmin',
    };

    isLocalDev() {
        const dotTestFoundInLocation = window.location.href.indexOf('.test') !== -1;

        return dotTestFoundInLocation;
    }

    getProjectName() {
        return global.sat.project_name;
    }

    isApi2Convert() {
        return global.sat.project_name === this.projectNames.api2convert;
    }

    isAudio2Edit() {
        return global.sat.project_name === this.projectNames.audio2edit;
    }

    isCompress2go() {
        return global.sat.project_name === this.projectNames.compress2go;
    }

    isEbook2edit() {
        return global.sat.project_name === this.projectNames.ebook2edit;
    }

    isMetadata2Go() {
        return global.sat.project_name === this.projectNames.metadata2go;
    }

    isImg2go() {
        return global.sat.project_name === this.projectNames.img2go;
    }

    isOcr2edit() {
        return global.sat.project_name === this.projectNames.ocr2edit;
    }

    isOnlineConvert() {
        return global.sat.project_name === this.projectNames.onlineConvert;
    }

    isPdf2go() {
        return global.sat.project_name === this.projectNames.pdf2go;
    }

    isVideo2edit() {
        return global.sat.project_name === this.projectNames.video2edit;
    }

    isSatAdmin() {
        return global.sat.project_name === this.projectNames.satAdmin;
    }
}

if (!global.gProjectHelper) {
    global.gProjectHelper = new ProjectHelper();
}

let projectHelper = global.gProjectHelper;

export { projectHelper };
