var browser = {
    FIREFOX: 'firefox',
    CHROME: 'chrome',
    CHROME_HEADLESS: 'chrome_headless',
    OPERA: 'opera',
    EDGE: 'edge',
};

var userAgentHas = function (t) {
    var ua = navigator.userAgent.toLowerCase();

    return ua.indexOf(t.toLowerCase()) >= 0;
};

var isOpera = function () {
    return !!window.opera || userAgentHas(' OPR/');
};

var isEdge = function () {
    return userAgentHas(' Edg/');
};

var isChrome = function () {
    if (isOpera() || isEdge()) {
        return false;
    }

    if (isHeadlessChrome()) {
        return false;
    }

    return !!window.chrome;
};

var isHeadlessChrome = function () {
    if (isOpera()) {
        return false;
    }

    return userAgentHas(' HeadlessChrome/') || userAgentHas(' HeadlessChromium/');
};

var isFirefox = function () {
    return typeof InstallTrigger !== 'undefined';
};

/**
 * @return {boolean}
 */
var isMobile = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

/**
 * @return {string|null}
 */
var getBrowser = function () {
    if (isOpera()) {
        return browser.OPERA;
    }

    if (isEdge()) {
        return browser.EDGE;
    }

    if (isFirefox()) {
        return browser.FIREFOX;
    }

    if (isHeadlessChrome()) {
        return browser.CHROME_HEADLESS;
    }

    if (isChrome()) {
        return browser.CHROME;
    }

    return null;
};

var getBrowserLanguage = function () {
    var userLang = navigator.language;

    if (userLang.includes('-')) {
        return userLang.slice(0, userLang.indexOf('-'));
    }

    return userLang;
};

export { getBrowser };
export { isMobile };
export { browser };
export { getBrowserLanguage };
