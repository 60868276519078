import {apiRequestManager} from '../api/api-request-manager';
import {testIsNonEmptyString} from '../../lib/test-and-assert/test-base';
import {globalLogger} from '../../qaamgo/helper/global-logger';
import {csrfManager} from '../csrf/csrf-manager';
import {SESSION_STORAGE_USER} from './user';

const LOGIN_FAIL_INVALID_DATA = 'error-login-fail-invalid-data';
const LOGIN_FAIL_UNKNOWN_ERROR = 'error-login-fail-unknown-error';
const LOGIN_FAIL_CONNECTION_ISSUE = 'error-login-fail-connection-issue';
const LOGIN_FAIL_INVALID_CAPTCHA = 'error-login-fail-invalid-captcha';
const LOGIN_FAIL_NOT_ENABLED = 'error-login-fail-not-enabled';

export {LOGIN_FAIL_INVALID_DATA};
export {LOGIN_FAIL_UNKNOWN_ERROR};
export {LOGIN_FAIL_CONNECTION_ISSUE};
export {LOGIN_FAIL_INVALID_CAPTCHA};
export {LOGIN_FAIL_NOT_ENABLED};

const REGISTER_FAIL_INVALID_EMAIL = 'error-register-fail-invalid-email';
const REGISTER_FAIL_UNKNOWN_ERROR = 'error-register-fail-unknown-error';
const REGISTER_FAIL_CONNECTION_ISSUE = 'error-register-fail-connection-issue';
const REGISTER_FAIL_INVALID_CAPTCHA = 'error-register-fail-invalid-captcha';

export {REGISTER_FAIL_INVALID_EMAIL};
export {REGISTER_FAIL_UNKNOWN_ERROR};
export {REGISTER_FAIL_CONNECTION_ISSUE};
export {REGISTER_FAIL_INVALID_CAPTCHA};

const PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_CAPTCHA = 'error-password-reset-send-mail-fail-invalid-captcha';
const PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_CSRF = 'error-password-reset-send-mail-fail-invalid-csrf';
const PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_EMAIL = 'error-password-reset-send-mail-fail-invalid-email';
const PASSWORD_RESET_SEND_MAIL_FAIL_UNKNOWN_ERROR = 'error-password-reset-send-mail-fail-unknown-error';
const PASSWORD_RESET_SEND_MAIL_FAIL_CONNECTION_ISSUE = 'error-password-reset-send-mail-fail-connection-issue';

export {PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_CAPTCHA};
export {PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_CSRF};
export {PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_EMAIL};
export {PASSWORD_RESET_SEND_MAIL_FAIL_UNKNOWN_ERROR};
export {PASSWORD_RESET_SEND_MAIL_FAIL_CONNECTION_ISSUE};

class UserApi {
    /**
     * @return {Promise<MeData>}
     */
    async getMe() {
        const response = await apiRequestManager.get('api/user/me');
        const meData = response.data;

        try {
            window.sessionStorage.setItem(SESSION_STORAGE_USER, JSON.stringify(meData));
        } catch (e) {
        }

        return meData;
    }

    /**
     * @param {User} user
     *
     * @return {Promise}
     */
    async login(user) {
        const url = '/api/user/login';

        const data = {
            _api_username: user.getEmail(),
            _api_password: user.getPassword(),
        };

        const username = user.getUsername();

        if (testIsNonEmptyString(username)) {
            data._api_username = username;
        }

        try {
            data._csrf = await csrfManager.getCsrfLogin();

            const response = await apiRequestManager.postWithCaptcha(url, data);

            return response?.data?.url ?? null;
        } catch (error) {
            globalLogger.log('login helper - login fail', error);

            if (error?.response?.data?.message === 'invalid_captcha') {
                return Promise.reject(LOGIN_FAIL_INVALID_CAPTCHA);
            }

            if (error?.response?.data?.badCredentials === true) {
                return Promise.reject(LOGIN_FAIL_INVALID_DATA);
            }

            if (error?.response?.data?.disabled === true) {
                return Promise.reject(LOGIN_FAIL_NOT_ENABLED);
            }

            if (error.isMissingResponseError) {
                return Promise.reject(LOGIN_FAIL_CONNECTION_ISSUE);
            }

            return Promise.reject(LOGIN_FAIL_UNKNOWN_ERROR);
        }
    }

    /**
     * @param {User} user
     *
     * @return {Promise}
     */
    register(user) {
        const url = '/api/client/user/register';

        const data = {
            _email: user.getEmail(),
            _password: user.getPassword(),
            _newsletter: user.hasNewsletter(),
            _user_locale: user.getLanguage(),
        };

        return csrfManager
            .getCsrfRegister()
            .then((token) => {
                data._csrf = token;

                return apiRequestManager.postWithCaptcha(url, data);
            })
            .catch((error) => {
                globalLogger.log('register helper - register fail', error);

                if (error?.response?.data?.message === 'invalid_captcha') {
                    return Promise.reject(REGISTER_FAIL_INVALID_CAPTCHA);
                }

                if (error?.response?.data?.message === 'email_invalid') {
                    return Promise.reject(REGISTER_FAIL_INVALID_EMAIL);
                }

                if (error.isMissingResponseError) {
                    return Promise.reject(REGISTER_FAIL_CONNECTION_ISSUE);
                }

                return Promise.reject(REGISTER_FAIL_UNKNOWN_ERROR);
            });
    }

    /**
     * @return {Promise}
     */
    resendConfirmationMail(email) {
        const url = '/api/register-resend';
        const data = {
            _email: email
        };

        return csrfManager
            .getCsrfResendConfirmation()
            .then((token) => {
                data._csrf = token;

                return apiRequestManager.postWithCaptcha(url, data);
            })
            .catch((error) => {
                globalLogger.log('register helper - resent confirmation', error);

                if (error.isMissingResponseError) {
                    return Promise.reject(REGISTER_FAIL_CONNECTION_ISSUE);
                }

                return Promise.reject(REGISTER_FAIL_UNKNOWN_ERROR);
            });
    }

    /**
     * @return {Promise}
     */
    async claimEducationalAccess() {
        const url = '/api/user/education/claim';
        const data = {};

        try {
            data._csrf = await csrfManager.getCsrfEducationalClaim();

            await apiRequestManager.postWithCaptcha(url, data);
        } catch (error) {
            globalLogger.log('user api - education - claim fail', error);

            if (error.isMissingResponseError) {
                return Promise.reject(REGISTER_FAIL_CONNECTION_ISSUE);
            }

            return Promise.reject(REGISTER_FAIL_UNKNOWN_ERROR);
        }
    }

    async sendEducationalConfirmationMail() {
        const url = '/api/user/education/request-confirmation';
        const data = {};

        try {
            data._csrf = await csrfManager.getCsrfEducationalRequestConfirmationMail();

            await apiRequestManager.postWithCaptcha(url, data);
        } catch (error) {
            globalLogger.log('user api - education - request confirmation fail', error);

            if (error.isMissingResponseError) {
                return Promise.reject(REGISTER_FAIL_CONNECTION_ISSUE);
            }

            return Promise.reject(REGISTER_FAIL_UNKNOWN_ERROR);
        }
    }

    /**
     * @param {String} email
     *
     * @returns {Promise}
     */
    sendPasswordResetMail(email) {
        const url = '/api/user/request-password-reset';

        const data = {
            _email: email,
        };

        return csrfManager
            .getCsrfRequestPasswordReset()
            .then((token) => {
                data._csrf = token;

                return apiRequestManager.postWithCaptcha(url, data);
            })
            .catch((error) => {
                globalLogger.log('user api helper - send password reset mail fail', error);

                if (error?.response?.data?.message === 'invalid_captcha') {
                    return Promise.reject(PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_CAPTCHA);
                }

                if (error?.response?.data?.message === 'forbidden') {
                    return Promise.reject(PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_CSRF);
                }

                if (error?.response?.data?.message === 'email_invalid') {
                    return Promise.reject(PASSWORD_RESET_SEND_MAIL_FAIL_INVALID_EMAIL);
                }

                if (error.isMissingResponseError) {
                    return Promise.reject(PASSWORD_RESET_SEND_MAIL_FAIL_CONNECTION_ISSUE);
                }

                return Promise.reject(PASSWORD_RESET_SEND_MAIL_FAIL_UNKNOWN_ERROR);
            });
    }
}

if (!global.gUserApi) {
    global.gUserApi = new UserApi();
}

/** @type {UserApi} */
const userApi = global.gUserApi;

export {userApi};
