import { getAssertMessage } from '../../lib/test-and-assert/get-assert-message';
import { testIsMeData, testIsUserProduct, testIsUserSetting } from './test';

/**
 * @param {MeData} meData
 */
function assertIsMeData(meData) {
    if (!testIsMeData(meData)) {
        throw new Error(getAssertMessage('meData is not valid'));
    }
}

export { assertIsMeData };

function assertIsUserSetting(setting, message) {
    const isValidUserSetting = testIsUserSetting(setting);

    if (isValidUserSetting) {
        return;
    }

    throw new Error(getAssertMessage('value is not a valid user setting', message));
}

export { assertIsUserSetting };

function assertIsUserProduct(product, message) {
    const isValidUserProduct = testIsUserProduct(product);

    if (isValidUserProduct) {
        return;
    }

    throw new Error(getAssertMessage('value is not a valid user product', message));
}

export { assertIsUserProduct };
