import { userApi } from './user-api';
import { loginExtension } from '../../lib/browser-extension/browser-extension';
import { testIsNonEmptyString, testIsNull, testIsUndefined } from '../../lib/test-and-assert/test-base';
import { globalLogger } from '../../qaamgo/helper/global-logger';
import { useUserStore } from '../store/modules/user-store';
import { User } from './user';
import { assertIsString } from '../../lib/test-and-assert/assert-base';

class UserManager {
    #fetchUserRequest = null;
    get #store() {
        return useUserStore();
    }

    /**
     * @param {User} user
     *
     * @return {Promise}
     */
    async logInUser(user) {
        const loginResponse = await this.#store.logInUser(user);

        try {
            // whenever the user is logged in via the webpage we also want them logged in in the extension
            loginExtension().then(() => {
                // do nothing
            });
        } catch (e) {
            // do nothing
        }

        return loginResponse;
    }

    /**
     * @param {User} user
     *
     * @return {Promise}
     */
    async registerUser(user) {
        return await this.#store.registerUser(user);
    }

    /**
     * @param {User} user
     *
     * @return {Promise}
     */
    async registerAndLogInUser(user) {
        await this.registerUser(user);

        return this.logInUser(user);
    }

    /**
     * @param {Boolean} [forceRefresh=false]
     *
     * @return {Promise<User>}
     */
    async getUser(forceRefresh) {
        if (testIsUndefined(forceRefresh)) {
            forceRefresh = false;
        }

        const store = this.#store;

        if (forceRefresh) {
            this.#fetchUserRequest = null;
        } else if (store.getUser !== null) {
            return store.getUser;
        }

        if (this.#fetchUserRequest !== null) {
            return this.#fetchUserRequest;
        }

        this.#fetchUserRequest = new Promise((resolve, reject) => {
            let attempt = 0;

            async function tryToGetUser() {
                attempt++;

                try {
                    const newUser = await store.fetchUser();

                    resolve(newUser);
                } catch (e) {
                    if (attempt > 3) {
                        globalLogger.log('user store - could not get user', e);

                        reject();

                        return;
                    }

                    setTimeout(tryToGetUser, 3000);
                }
            }

            tryToGetUser();
        });

        return this.#fetchUserRequest;
    }

    /**
     * @return {Promise<MeData>}
     */
    async getFreshMe() {
        const user = await this.getUser(true);

        return user.getRawMeData();
    }

    /**
     * @return {Promise<User>}
     */
    getFreshUser() {
        return this.getUser(true);
    }

    /**
     * @return {Promise}
     */
    resendConfirmationMail(email) {
        return userApi.resendConfirmationMail(email);
    }

    /**
     * @return {Promise}
     */
    claimEducationalAccess() {
        return userApi.claimEducationalAccess();
    }

    async claimEducationalAccessAndRedirectToSuccessPage() {
        globalLogger.log('user manager - edu claim - start');

        try {
            await this.claimEducationalAccess();
        } catch (e) {
            globalLogger.log('user manager - edu claim - fail');

            throw e;
        }

        globalLogger.log('user manager - edu claim - success');

        // refresh user
        await this.getFreshMe();

        window.allowLeave = true;
    }

    sendEducationalConfirmationMail() {
        return userApi.sendEducationalConfirmationMail();
    }

    /**
     * @param {String} email
     *
     * @return {Promise}
     */
    sendPasswordResetMail(email) {
        return userApi.sendPasswordResetMail(email);
    }

    /**
     * @return {?User}
     */
    getCurrentUserFromStore() {
        return this.#store.getUser;
    }

    generateDisplayName(name, email) {
        if (testIsNull(name)) {
            return '';
        }

        if (testIsNull(email)) {
            return '';
        }

        if (name.trim() === '') {
            return '';
        }

        let _name = name;

        if (testIsNonEmptyString(email)) {
            const reg1 = /^USER_[a-f0-9]{12}$/i;
            const reg2 = /^PDF2GO_[a-f0-9]{12}$/i;

            if (reg1.test(name) || reg2.test(name)) {
                _name = email;
            }
        }

        if (_name.length > 23) {
            _name = _name.slice(0, 20) + '...';
        }

        return _name;
    }
}

if (!global.gUserManager) {
    global.gUserManager = new UserManager();
}

/** @type UserManager */
const userManager = global.gUserManager;

export { userManager };
